<!-- =========================================================================================
    File Name: login.vue
    Description: Login component
    ----------------------------------------------------------------------------------------
    Item Name: RIM-Nat / Plateforme 3D collaborative

    Author URL:
========================================================================================== -->

<template>
  <div class="mt-2">
    <p v-if="this.text" class="mt-2">{{$t('loginRequiredMessage')}}</p>
    <!-- <vs-alert color="danger" class="mt-5" icon-pack="feather" icon="icon-rotate-cw">{{$t('migrationLoginMessage')}}</vs-alert> -->
    <vs-button :class="this.text ? 'mt-4' : ''" @click="loginAuth0" :color="this.color">{{$t('loginOrRegister')}}</vs-button>
  </div>
</template>

<script>
// import LoginAuth0 from './LoginAuth0.vue'

export default {
  name: 'login',
  props: {
    text: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'primary'
    }
  },
  components: {
    // LoginAuth0
  },
  methods: {
    checkLogin () {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        localStorage.removeItem('loggedIn')

        // // Close animation if passed as payload
        // // this.$vs.loading.close()

        // this.$vs.notify({
        //   title: 'Login Attempt',
        //   text: 'You are already logged in !',
        //   iconPack: 'feather',
        //   icon: 'icon-alert-circle',
        //   color: 'warning'
        // })

        // return false
      }
      return true
    },

    loginAuth0 () {
      if (!this.checkLogin()) return
      this.$auth.login({ target: this.$route.query.to })
    },
    registerUser () {
      if (!this.checkLogin()) return
      this.$router.push('/pages/register').catch(() => {})
    }
  }
}

</script>

<style lang="scss">
.login-tabs-container {
  min-height: 505px;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
}
</style>