<template>
  <div v-if="this.mounted">
    <!-- utilisateur sans compte -->
    <div v-if="!loggedin">
      <vx-card :title="$t('loginRequired')" class="mb-8" >
        <login></login>
      </vx-card>
    </div>

    <!-- utilisateur avec un compte ... -->
    <template v-if="loggedin">
      <div v-if="!emailVerified">
        <vx-card :title="$t('emailNotVerifiedTitle')" title-color="warning">
          <div class="w-full">
            {{$t('emailNotVerifiedMessage')}} <strong>{{this.$store.state.AppActiveUser.user_email}}</strong>
          </div>
          <div class="w-full mt-4">
            <vs-alert color="warning" icon-pack="feather" icon="icon-alert-triangle">{{$t('emailNotVerifiedMessage2')}}</vs-alert>
          </div>
          <div class="w-full mt-4">
            {{$t('emailNotVerifiedMessage3')}}
          </div>
          <login :text="false" color="warning"></login>
        </vx-card>
      </div>
      <template v-else>
        <h2 class="mb-10">{{ `${$t('welcome')} ${this.$store.state.AppActiveUser.first_name} ${this.$store.state.AppActiveUser.last_name}` | capitalize }}</h2>

        <!-- ... mais sans portail -->
        <template v-if="!hasPortals">
          <vs-alert color="warning" icon-pack="feather" icon="icon-alert-triangle" class="mb-8">{{$t('userWithoutPortalMessage')}}</vs-alert>
        </template>
        
        <!-- ... avec au moins un portail -->
        <div v-if="hasPortals">
          <h3 class="my-8">{{$t('choosePortal')}}</h3>
          <vx-card
            :key="portal.value"
            v-for="portal in this.$store.state.portals"
            :title="portal.label"
            class="mb-8"
            title-color="#fff"
            content-color="#fff"
            card-background="linear-gradient(to right, #708e1b, #8bb516)">
            <vs-row>
              <vs-col vs-offset="7" vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
                <vs-button class="w-full" color="#fff" type="border" size="large" icon-pack="feather" icon="icon-home"
                @click="gotoPortal(portal.value)"
                >{{$t('AccessButton')}}</vs-button>
              </vs-col>
            </vs-row>
          </vx-card>
        </div>

        <!-- formulaire d'ajout de portail ou project -->
        <vx-card :title="$t('howToGetAccess')"          
            title-color="primary">
          <div class="vx-row">
            <div class="vx-col w-full sm:w-1/1 lg:w-1/2 mb-base">
              <vx-card :title="$t('youKnowAnAdministratorTitle')"
              content-color="dark" >
                {{$t('youKnowAnAdministratorMessage')}}
              </vx-card>
            </div>
            <div class="vx-col w-full sm:w-1/1 lg:w-1/2 mb-base">
              <vx-card :title="$t('youHaveAnInviteTitle')"
              content-color="dark">
                <p>{{$t('youHaveAnInviteMessage')}}</p>
                <vs-input v-model="shareCode" @blur="gotoShare" @keyup.enter="gotoShare" class="w-full"></vs-input>
              </vx-card>
            </div>
          </div>
        </vx-card>
      </template>
    </template>
  </div>
</template>
<script>
import Login from './components/login.vue'
import axios from '@/axios.js'
import axiosNoHeaders from 'axios'
import logActivity from '@/logActivity.js'

export default {
  components: {
    Login, 
    logActivity
  },
  data () {
    return {
      mounted: false, 
      shareCode: '',

      ipdataUrl: 'https://api.ipdata.co/?api-key=',
      ipdataKey: '140859599e0c9f997c95a47892839a818276fcb277a28771809de3bb',
      ipData: {},

      // ROYBON / API calls
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      getUserAuthorizedPortalsMenuList: 'getUserAuthorizedPortalsMenuList', //
      getUserGlobalCaps: 'getUserGlobalCaps',
      capsRefreshFrequency: 5000, 

      // ROYBON / Parcours de connexion
      portals: {}, //
      current_user_global_caps : {},
      PortalsListDispatched : false
    }
  },
  beforeMount () {
    if (!this.hasPortals) {
      // this.$router.push({ path: '/pages/login'})
    } else if (this.portalRedirect) {
      if (this.$store.state.portals.length === 1) {
        // this.$router.push({ path: `/${this.lastPortal}/`})
      }
    }  
  },
  mounted () {
    const storedRedirect = sessionStorage.getItem('loginRedirect')
    sessionStorage.setItem('loginRedirect', '')
    sessionStorage.removeItem('loginRedirect')
    if (storedRedirect !== '/' && storedRedirect !== null && storedRedirect !== '') {
      this.$router.push(`${storedRedirect}`).catch(() => {})
    }
    this.mounted = true
    this.updatePortalList()
    this.updateUserGlobalCaps()
    this.getIpData()
  },
  created () {
    this.timer = setInterval(this.updatePortalList, this.capsRefreshFrequency)
    this.timer = setInterval(this.updateUserGlobalCaps, this.capsRefreshFrequency)
  },
  methods: {
    gotoPortal (portal_slug) {
      this.$store.commit('UPDATE_PORTAL_SLUG', portal_slug)
      this.$store.dispatch('updatePortalSlug', portal_slug)
      this.$router.push(`${portal_slug}`).catch(() => {})
    },
    gotoShare () {
      let shareCode = this.shareCode
      if (shareCode.includes('/')) {
        shareCode = shareCode.substring(shareCode.lastIndexOf('/') + 1)
      }
      shareCode = shareCode.toLowerCase().trim().replace(/\s/g, '')
      if (shareCode !== '') this.$router.push(`/s/${shareCode}`).catch(() => {})
    },

    // ROYBON / Update de la liste des portals
    updatePortalList () {
      if (this.loggedin && this.emailVerified) {
        const params = `user_id=${this.$store.state.AppActiveUser.user_id}&user_hash=${this.$store.state.AppActiveUser.user_hash}`
        const rimnat_api_call_url = `${this.api_server_baseurl}/user/getUserAuthorizedPortalsMenuList?${params}`
        axios.get(rimnat_api_call_url, {
        })
          .then((response) => { 
            if (response !== null) {
              if (response.data !== null && response.data != 'null') {
                this.portals = response.data
              } else {
                this.portals = {}
              }
              this.$store.commit('UPDATE_PORTALS', this.portals)
              this.$store.dispatch('updatePortals', this.portals)
              this.PortalsListDispatched = true
            }
          })
          .catch((error)   => { console.log(error) }) 
      }
    },
    
    // ROYBON / Get global_caps du user
    updateUserGlobalCaps () {
      if (this.loggedin && this.emailVerified) {
        const params = `user_id=${this.$store.state.AppActiveUser.user_id}&user_hash=${this.$store.state.AppActiveUser.user_hash}`
        const rimnat_api_call_url = `${this.api_server_baseurl}/user/${this.getUserGlobalCaps}?${params}`
        axios.get(rimnat_api_call_url, {
        })
          .then((response) => { 
            if (response.data != null && response.data != 'null') {
              this.current_user_global_caps = response.data 
              this.$store.commit('UPDATE_CURRENT_USER_GLOBAL_CAPS', this.current_user_global_caps)
              this.$store.dispatch('updateCurrentUserGlobalCaps', this.current_user_global_caps)
            }
          })
          .catch((error)   => { console.log(error) })
      }
    },
    getIpData () {
      if (typeof this.$store.state.ipData == 'undefined' || !this.$store.state.ipData || typeof this.$store.state.ipData.ip == 'undefined') {
        const url = `${this.ipdataUrl}${this.ipdataKey}`
        axiosNoHeaders.get(url, {
        })
          .then((response) => { 
            if (response.data != null && response.data != 'null' && response.data.ip != null) {
              this.ipData = response.data
              this.$store.dispatch('updateIpData', this.ipData)
            }
          })
          .catch((error)   => { console.log(error) })
      } else {
        this.ipData = this.$store.state.ipData
      }
    }

  },
  watch: {
    ipData () { 
      if (this.loggedin && this.ipData != null && typeof this.ipData.ip != 'undefined') {
        const synthesisIpData = `${this.ipData.ip} | ${this.ipData.country_name} | ${this.ipData.city} | ${this.ipData.postal} | ${this.ipData.region}`
        logActivity.add('user_ip_data_synthesis', synthesisIpData)
        logActivity.add('user_ip_data', JSON.stringify(this.ipData))
      }
    }
  },
  computed: {
    loggedin () { 
      let returned = false
      if (typeof this.$store.state.AppActiveUser.uid !== 'undefined' && typeof this.$store.state.AppActiveUser.user_id !== 'undefined') {
        if (this.$store.state.AppActiveUser.uid !== 1 && this.$store.state.AppActiveUser.user_id !== null && this.$store.state.AppActiveUser.user_id !== 0 && this.$store.state.AppActiveUser.user_id !== '') {
          returned = true
          logActivity.add('user_login_success', this.$store.state.AppActiveUser.uid)
        }
      }
      return returned
    },
    emailVerified () {
      let returned = false
      if (this.loggedin && typeof this.$store.state.AppActiveUser.email_verified != 'undefined') {
        returned = this.$store.state.AppActiveUser.email_verified
      }
      return returned
    },
    hasPortals () { 
      let returned = false
      if (typeof this.$store.state.portals !== 'undefined' && this.$store.state.portals) {
        if (this.$store.state.portals.length > 0) {
          returned = true
        }
      }
      return returned
    },
    lastPortal () { 
      let returned = ''
      if (this.hasPortals) {
        if (this.$store.state.portal_slug !== '' && this.$store.state.portal_slug !== null) {
          returned = this.$store.state.portal_slug
        }
      }
      return returned
    },
    portalRedirect () { 
      return (this.hasPortals && this.lastPortal !== '')
    }
  }
}
</script>
